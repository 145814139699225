<template>
  <div style="background: #ffffff" class="pb-5" id="videoSection">
    <v-container>
      <v-row>
        <v-col>
          <div class="heading">
            WizFit Video Challenge
            <div class="hLine"></div>
          </div>
          <div>Complete the challenge & Win Big!</div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="8">
          <v-row>
            <v-col cols="12" class="py-5">
              <div @click="playVideo()" style="cursor: pointer">
                <video
                  :poster="videoToPlay.video_thumbnail"
                  style="
                    width: 100%;
                    height: 350px;
                    object-fit: contain;
                    background: rgba(29, 29, 29, 0.2);
                  "
                >
                  <source :src="videoToPlay.video_url" />
                </video>
                <img
                  src="@/assets/play.png"
                  :width="$vuetify.breakpoint.smAndUp ? '80' : '60'"
                  alt=""
                  class="playImg"
                />
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4" sm="2" v-for="(item, i) in videoList" :key="i">
              <!-- <img :src="item.thumbnail" alt="" width="100%" /> -->
              <div
                @mouseover="item.button_flag = true"
                @mousedown="item.button_flag = true"
                @mouseup="item.button_flag = true"
                @mousemove="item.button_flag = true"
                @mouseleave="item.button_flag = mouseLeave()"
              >
                <div
                  style="cursor: pointer"
                  class="d-flex justify-center"
                  :class="!item.is_next_video ? 'pt-4' : ''"
                >
                  <img
                    :src="getThumbnail(item)"
                    alt=""
                    width="80%"
                    @click="selectVideo(item, false)"
                  />
                </div>
                <div
                  v-if="item.is_next_video"
                  class="d-flex justify-center"
                  style="width: 100%"
                >
                  <v-btn
                    class="text-capitalize px-2"
                    dark
                    color="#7253cf"
                    @click="selectVideo(item, true)"
                  >
                    <v-icon color="white" small>mdi-play</v-icon>
                    <span style="color: white; font-size: 12px">Watch now</span>
                  </v-btn>
                </div>
                <div
                  style="transition: all 2s ease-in-out 6s"
                  class="watchAgain d-flex justify-center px-0 py-0"
                  v-if="item.is_watched && item.button_flag"
                >
                  <v-btn
                    text
                    class="text-capitalize"
                    style="border: 1px solid #2c1963; border-radius: 6px"
                    @click="watchagain(item)"
                  >
                    <span style="color: #2c1963; font-size: 12px"
                      >Watch again</span
                    >
                  </v-btn>
                </div>
                <div
                  v-if="!item.is_next_video && !item.is_watched"
                  class="d-flex justify-center mt-2"
                >
                  <v-icon>mdi-lock</v-icon>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <video-player-modal></video-player-modal>
  </div>
</template>
<script>
import { API_GET_STUDENT_VIDEO_PROGRESS } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapActions } from "vuex";
export default {
  name: "VideoSection",
  components: {
    VideoPlayerModal: () => import("./VideoSectionViewer/VideoSectionViewer"),
  },
  data() {
    return {
      student: null,
      videoList: [],
      videoToPlay: {},
      btnSelector: true,
      videoViewer: {
        flag: false,
        src: "",
        type: "video",
        obj: {},
      },
    };
  },
  created() {
    this.$root.$refs.videoSection = this;
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({ toggleVideoModal: "videoSection/toggleModal" }),
    mouseLeave() {
      setTimeout(() => {
        return false;
      }, 5000);
    },
    playVideo() {
      // this.videoViewer.src = this.videoToPlay.video_url;
      // this.videoViewer.obj = this.videoToPlay;
      // this.videoViewer.flag = true;
      this.toggleVideoModal({
        show: true,
        obj: this.videoToPlay,
        src: this.videoToPlay.video_url,
      });
    },
    selectVideo(item, watch) {
      if (item.is_next_video) {
        console.log(item, watch);
        this.videoToPlay = item;
        if (watch) {
          this.playVideo();
        }
      }
    },
    getThumbnail(item) {
      if (item.is_next_video) {
        return item.active_thumbnail;
      } else {
        return item.thumbnail;
      }
    },
    watchagain(item) {
      // console.log(item, "watch again");
      // this.videoViewer.src = item.video_url;
      // this.videoViewer.flag = true;
      this.toggleVideoModal({
        show: true,
        obj: item,
        src: item.video_url,
      });
    },
    getDetails() {
      const successHandler = (res) => {
        console.log(res.data, "video");
        this.videoToPlay = res.data.next_video_to_play;
        this.videoList = res.data.video_watch_list;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["student_id"] = this.student;
      Axios.request_GET(
        API_GET_STUDENT_VIDEO_PROGRESS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitCompleted(value, watchTime) {
      console.log(value, "video has been 80% completed");
      let check = value.is_watched;
      if (!check) this.postVideoComplete(value, watchTime);
    },
    postVideoComplete(obj, watchTime) {
      const successHandler = (res) => {
        console.log(res.data);
        this.videoToPlay = res.data.next_video_to_play;
        this.videoList = res.data.video_watch_list;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["video"] = obj.id;
      formData["student"] = this.$route.query.student;
      formData["watch_time"] = watchTime;
      formData["is_video_watched"] = true;

      Axios.request_POST(
        API_GET_STUDENT_VIDEO_PROGRESS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    if (this.$route.query.student) {
      this.student = this.$route.query.student;
    }
    this.getDetails();
  },
};
</script>
<style scoped>
.heading {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.watchAgain {
  -webkit-transition: 5s all;
  -webkit-transition: 5s all;
  -webkit-transition-delay: 5s;
  -moz-transition: 5s all;
  -moz-transition-delay: 5s;
  -ms-transition: 5s all;
  -ms-transition-delay: 5s;
  -o-transition: 5s all;
  -o-transition-delay: 5s;
  transition: 5s all;
  transition-delay: 5s;
}
.playImg {
  position: absolute;
  right: 60%;
  margin-top: 120px;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .hLine {
    background-color: #2c1963;
    width: 30px;
    height: 3px;
    margin-top: 15px;
  }
  .playImg {
    position: absolute;
    right: 35%;
    margin-top: 140px;
  }
}
</style>
